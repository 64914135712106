setTimeout(() => {
    // Attach event listeners for hover and touch events
    document.querySelectorAll(`a[rel="prefetch"]`).forEach(link => {
        const url = link.href

        // On hover (for desktop and similar devices)
        link.addEventListener("mouseenter", () => {
                preloadPage(url)
            },
            { passive: true },
        ) // Adding a passive listener

        // On touchstart (for touch devices like tablets and phones)
        link.addEventListener("touchstart", () => {
                preloadPage(url)
            },
            { passive: true },
        ) // Adding a passive listener
    })
}, 1100)

// Function to create a prefetch link element
function preloadPage(url) {
    if (!document.querySelector(`link[rel="prefetch"][href="${url}"]`)) {
        const link = document.createElement("link")
        link.rel = "prefetch"
        link.href = url
        document.head.appendChild(link)
    }
}

export function createLazyLoadTrigger(elementsSelector: string, triggerOffset = 200, callback: () => void) {
    let isTriggered = false

    if ("IntersectionObserver" in window) {
        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        isTriggered = true
                        callback()
                        observer.disconnect()
                    }
                })
            },
            {rootMargin: `${triggerOffset}px`}
        )

        const elements = document.querySelectorAll(elementsSelector)
        elements.forEach(element => observer.observe(element))
    }
}

export function delayedInit(fn, delay = 500) {
    if ("requestIdleCallback" in window) {
        requestIdleCallback(() => {
            setTimeout(fn, delay)
        })
    } else {
        setTimeout(fn, delay)
    }
}
